.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  margin-top: 4em;
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  #background-color: #eb008b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  #font-size: calc(10px + 2vmin);
  #color: white;
}

.App-link {
  color: #eb008b;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

p {
  margin-bottom: 0;
}
h1, h2, h3, h4, h5, p {
  color: #eb008b;
}

button {
  width: 100%;
  background-color: #eb008b;
  border-color: #eb008b;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1025px) {
  .topbar {
    right: 3em;
  }
}
@media only screen and (max-width: 1025px) {
  .topbar {
    right: .5em;
  }
}

.topbar {
  position: fixed;
  top: 0.5em;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topbar a {
  max-width: unset !important;
  margin: 0.5em;
}

.gmaps {
  margin-bottom: 2em;
}
h3 {
  margin-bottom: 0em;
}

.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  max-width: 100%;
  position: relative;
  background-color: white;
  overflow: hidden;
}
.react-pdf__Page {
  /* max-width: calc(~"100% - 2em"); */     /* react build error */
  box-shadow: 0 0 8px rgba(0, 0, 0, .5);
  border-radius: 8px;
  margin: 1em;
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
  border-radius: 8px;
}
.react-pdf__message {
  padding: 20px;
  color: white;
}
.react-pdf__Page__textContent span {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}
.react-pdf__Page__textContent{
  left: 0 !important;
  width: 100% !important;
}

h4 {
  margin-bottom: 0;
}
.impressum {
  max-width: 100%;
}
.impressum pre {
  font-size: .7em;
}
pre {
  border-left: 0.3rem solid #eb008b;
}


/* experimentation */

.primary-button {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  color: #ebdbb2;
  cursor: pointer;
  display: block;
  height: 58px;
  margin: -9px 0 0 -9px;
  position: relative;
  width: 200px;
}

.primary-button__text {
  align-items: center;
  background-color: #b16286;
  bottom: 11px;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 11px;
  top: 0;
}

.primary-button::before {
  border: 2px solid #b16286;
  bottom: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  content: "";
  left: 7px;
  position: absolute;
  right: 0;
  top: 7px;
}

.primary-button:hover {
  color: #fbf1c7;
  background: transparent;
}
.primary-button:focus, .primary-button:active {
  outline: none;
  background: transparent;
  color: #fbf1c7;
}
.primary-button:active .primary-button__text {
  transform: translateX(9px) translateY(9px);
}